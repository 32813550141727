import { CANVAS_HEIGHT, CANVAS_WIDTH } from "~/client/lib/constants";
import { PauseIcon, PlayIcon, Scroll, Typo } from "@cochlearai/ui";
import React, { FC, useEffect, useState } from "react";
import { SenseUniqueTag, UploadStatus } from "~/client/types";
import { color, media } from "@cochlearai/util";
import { convertSenseTagValueToUILabel, timeToString } from "~/client/lib";

import styled from "styled-components";
import useCanvas from "~/client/hooks/useCanvas";
import useCurrentProject from "~/client/hooks/useCurrentProject";

const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ResultImage = styled(Scroll)`
  background: ${(p) => p.theme.colors.grey[10]};
  width: 732px;
  height: 422px;
  border-radius: 4px;
  flex-direction: column;
  position: relative;

  ${media.query.md} {
    width: 100%;
  }
`;

const CanvasContainer = styled.div<{ $show?: boolean }>`
  width: ${CANVAS_WIDTH}px;
  height: ${CANVAS_HEIGHT}px;
  display: flex;
  position: relative;
  opacity: ${(p) => (p.$show ? 1 : 0)};
  padding-top: 100px;
`;

const TagRegion = styled.div<{
  start: number;
  end: number;
}>`
  position: absolute;
  height: ${CANVAS_HEIGHT}px;
  display: flex;
  justify-content: flex-start;
  left: ${(p) => p.start}px;
  width: ${(p) => (p.end - p.start).toFixed(2)}px;
`;

const TagBackground = styled.div`
  background: ${(p) =>
    color.convertHexToRGB({
      hex: p.theme.colors.blue[60],
      alpha: 0.05,
    })};
  border: 1px solid ${(p) => p.theme.colors.blue[60]};
  width: 100%;
  height: 100%;
  border-radius: 4px;
`;

const TagTitle = styled(Typo)<{ right: boolean }>`
  position: absolute;
  color: ${(p) => p.theme.colors.blue[60]};
  width: max-content;
  top: unset;
  bottom: ${CANVAS_HEIGHT}px;
  right: ${(p) => (p.right ? 0 : "")};
  text-align: ${(p) => (p.right ? "right" : "left")};
`;

const Timeline = styled.div`
  position: absolute;
  bottom: 50px;
  width: 100%;
  border-top: 1px solid ${(p) => p.theme.colors.black};
`;

const TimeText = styled.div<{ left: number }>`
  position: absolute;
  left: ${(p) => p.left + "px"};
  bottom: -30px;
  color: ${(p) => p.theme.colors.black};

  & div {
    height: 10px;
    width: 1px;
    background: ${(p) => p.theme.colors.black};
  }
`;

const PlayButton = styled(Flex)<{ disabled: boolean }>`
  cursor: ${(p) => (p.disabled ? "default" : "pointer")};
  pointer-events: ${(p) => (p.disabled ? "none" : "")};
  opacity: ${(p) => (p.disabled ? 0.4 : 1)};
  width: fit-content;
  margin: 0 auto;
  margin-top: 18px;
  padding: 10px;
`;

interface SelectedTag {
  tags: SenseUniqueTag[];
  startPos: number;
  endPos: number;
  text: string;
}

interface Props {
  audioType: "sense" | "transcript";
  status?: UploadStatus;
  uniqueTags?: SenseUniqueTag[];
  duration?: number;
  selectedTime?: number;
  isPlay?: boolean;
  toggleAudio: () => void;
}

const AudioVisualizer: FC<Props> = ({
  audioType,
  status,
  uniqueTags,
  duration = 0,
  selectedTime,
  isPlay,
  toggleAudio,
}) => {
  const { currentProject } = useCurrentProject();
  const { canvasRef, backgroundRef, handleClick } = useCanvas({
    currentProject,
    audioType,
  });
  const [selectedTagInfo, setSelectedTagInfo] = useState<SelectedTag | null>();

  useEffect(() => {
    if (typeof selectedTime !== "number") {
      setSelectedTagInfo(null);
      return;
    }
    let text = "";

    const selectedTags = uniqueTags?.filter(({ start, end, name }) => {
      const isSelected = start <= selectedTime && selectedTime <= end;
      if (isSelected)
        text += `${convertSenseTagValueToUILabel(name)} (${start}s - ${end}s) `;
      return isSelected;
    });
    if (!(selectedTags && selectedTags.length > 0)) return;
    const startTime = Math.max(...selectedTags.map(({ start }) => start));
    const endTime = Math.max(...selectedTags.map(({ end }) => end));

    setSelectedTagInfo({
      tags: selectedTags,
      startPos: +((CANVAS_WIDTH * startTime) / duration).toFixed(2),
      endPos: +(
        (CANVAS_WIDTH * endTime) /
        (endTime > duration ? endTime : duration)
      ).toFixed(2),
      text,
    });
  }, [duration, selectedTime, uniqueTags]);

  return (
    <div
      style={{
        width: "fit-content",
        overflow: "auto",
        maxWidth: "-webkit-fill-available",
      }}
    >
      <ResultImage>
        <CanvasContainer
          $show={status === UploadStatus.FINISHED}
          onClick={handleClick}
        >
          <canvas ref={backgroundRef} />
          <canvas ref={canvasRef} style={{ position: "absolute" }} />
          {selectedTagInfo && (
            <TagRegion
              start={selectedTagInfo.startPos}
              end={selectedTagInfo.endPos}
            >
              <TagBackground />
              <TagTitle
                variant="caption2"
                right={CANVAS_WIDTH - selectedTagInfo.endPos < 100}
              >
                {selectedTagInfo.tags.map(({ name, start, end }, index) => (
                  <React.Fragment key={index}>
                    <span>
                      {`${convertSenseTagValueToUILabel(name)} (${timeToString(
                        start,
                      )} - ${timeToString(end)})`}
                    </span>
                    <br />
                  </React.Fragment>
                ))}
              </TagTitle>
            </TagRegion>
          )}
        </CanvasContainer>
        {duration > 0 && status === UploadStatus.FINISHED && (
          <Timeline>
            {Array.from({ length: 4 }).map((_, i) => {
              const curTime = Math.round(duration / 4) * i;
              const left = +((CANVAS_WIDTH * curTime) / duration).toFixed(2);
              return (
                <TimeText left={left} key={i}>
                  <div />
                  <Typo
                    variant="body"
                    style={{ marginLeft: i === 0 ? 0 : -30 }}
                  >
                    {timeToString(curTime)}
                  </Typo>
                </TimeText>
              );
            })}
          </Timeline>
        )}
      </ResultImage>
      <PlayButton disabled={status !== UploadStatus.FINISHED}>
        {!isPlay ? (
          <PlayIcon onClick={toggleAudio} width={30} height={30} />
        ) : (
          <PauseIcon onClick={toggleAudio} width={30} height={30} />
        )}
      </PlayButton>
    </div>
  );
};

export default AudioVisualizer;
