import { APIError, ProjectType, UploadHistory } from "~/client/types";
import { fetchGetUploadHistory, fetchGetUploadSession } from "~/client/api";
import { useAppDispatch, useCurrentProject } from "~/client/hooks";
import { useCallback, useEffect, useRef, useState } from "react";

import { SWR_KEY } from "~/client/types/swrKey";
import { setServerError } from "~/client/store/modules";
import { useParams } from "react-router-dom";
import useSWR from "swr";

function useUploadHistory() {
  const params = useParams<{ projectId?: string }>();
  const dispatch = useAppDispatch();
  const { currentProject } = useCurrentProject();
  const [historyList, setHistoryList] = useState<UploadHistory[]>([]);
  const isFetchingSessionInfo = useRef<boolean>(false);
  const isAPIproject = currentProject?.product_type === ProjectType.CLOUD_API;

  const { data, error, isValidating } = useSWR(
    params.projectId && isAPIproject
      ? SWR_KEY.UPLOAD_INITIALIZE_HISTORY_LIST
      : null,
    async () => {
      const res = await fetchGetUploadHistory({
        params: { project_id: params.projectId! },
      });
      return res.data;
    },
    {
      suspense: true,
      revalidateOnFocus: false,
    },
  );

  const getSessionInfo = useCallback(
    async (session_id: string) => {
      if (!params.projectId || isFetchingSessionInfo.current) return;

      isFetchingSessionInfo.current = true;
      try {
        const res = await fetchGetUploadSession({
          params: {
            project_id: params.projectId,
            session_id,
          },
        });
        const { data } = res;
        return data;
      } catch (e) {
        console.error(e);
        const error = e as APIError<unknown>;
        dispatch(setServerError(error));
      } finally {
        isFetchingSessionInfo.current = false;
      }
    },
    [dispatch, params.projectId],
  );

  useEffect(() => {
    if (!data) return;
    setHistoryList(data);
  }, [data]);

  useEffect(() => {
    if (!error) return;
    const e = error as APIError<unknown>;
    dispatch(setServerError(e));
  }, [dispatch, error]);

  return {
    historyList,
    getSessionInfo,
    isValidating,
    isValidatingSession: isFetchingSessionInfo.current,
  };
}

export default useUploadHistory;
