import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { ProjectType, SenseCategory } from "~/client/types";
import styled, { useTheme } from "styled-components";

import { SENSE_TAGS_BY_CATEGORY } from "~/client/lib/constants";
import { TagCategory } from "~/client/components";
import { Typo } from "@cochlearai/ui";
import useAnalyticsTrackingGroup from "~/client/hooks/useAnalyticsTrackingGroup";
import useCurrentProject from "~/client/hooks/useCurrentProject";
import useProjectTags from "~/client/hooks/useProjectTags";
import useSdkTags from "~/client/hooks/useSdkTags";

const SetButtonContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  margin-top: 30px;
`;

const TagsCountBadge = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background: ${(p) => p.theme.colors.black};
  color: ${(p) => p.theme.colors.white};
  margin-left: 8px;
`;

interface Props {
  open?: boolean;
  close?: () => void;
}

const AnalyticsTagSelection: FC<Props> = ({ open, close }) => {
  const { colors } = useTheme();
  const { currentProject } = useCurrentProject();
  const { sdkTags } = useSdkTags();
  const { projectTags } = useProjectTags({ currentProject });
  const { tags, setTags, clearAll, update, reset } =
    useAnalyticsTrackingGroup();
  const [selectedTagCategory, setSelectedTagCategory] = useState<SenseCategory>(
    SenseCategory.ALL,
  );
  const [isSelectAll, setIsSelectAll] = useState<boolean>(false);

  const categoryTags = useMemo(() => {
    return (
      currentProject?.product_type === ProjectType.EDGE_SDK
        ? projectTags.map((tag) => ({ value: tag.id, label: tag.name }))
        : sdkTags
    ).filter((item) => {
      if (selectedTagCategory === SenseCategory.ALL) {
        return true;
      }
      return SENSE_TAGS_BY_CATEGORY[selectedTagCategory].includes(item.value);
    });
  }, [currentProject?.product_type, projectTags, sdkTags, selectedTagCategory]);

  const onClickTags = useCallback(
    (item) => {
      if (!tags.some(({ value }) => value === item.value)) {
        setTags([...tags, ...[item]]);
      } else {
        setTags(tags.filter((data) => data.value !== item.value));
      }
    },
    [tags, setTags],
  );

  const onClickSelectAll = useCallback(
    (e) => {
      setTags(
        e.target.checked
          ? tags.concat(
              categoryTags.filter(
                (item) => !tags.some(({ value }) => value === item.value),
              ),
            )
          : tags.filter((item) => {
              if (selectedTagCategory === SenseCategory.ALL) {
                return false;
              }
              return (
                SENSE_TAGS_BY_CATEGORY[selectedTagCategory].indexOf(
                  item.value,
                ) === -1
              );
            }),
      );
    },
    [categoryTags, selectedTagCategory, setTags, tags],
  );

  useEffect(() => {
    let isNotSelected = false,
      i = 0;

    do {
      if (!tags.some(({ value }) => value === categoryTags[i]?.value)) {
        isNotSelected = true;
      }
      i++;
    } while (!isNotSelected && i < categoryTags.length);

    setIsSelectAll(!isNotSelected);
  }, [categoryTags, tags]);

  useEffect(() => {
    if (open) {
      reset();
    }
  }, [reset, open]);

  return (
    <>
      <Typo variant="h3">Tags Selection</Typo>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginTop: "20px",
          marginBottom: "10px",
        }}
      >
        <Typo variant="caption2" style={{ color: colors.grey[50] }}>
          Select tags
        </Typo>
        <TagsCountBadge>
          <Typo variant="caption3">{tags.length}</Typo>
        </TagsCountBadge>
      </div>
      <TagCategory
        tags={categoryTags}
        onClickItem={onClickTags}
        selectedItems={tags}
        selectedCategory={selectedTagCategory}
        onClickCategory={setSelectedTagCategory}
        style={{ gridTemplateColumns: "repeat(1, 1fr)" }}
        isSelectAll={isSelectAll}
        onClickSelectAll={onClickSelectAll}
      />
      <SetButtonContainer>
        <Typo
          variant="button"
          style={{
            color: colors.blue[60],
            cursor: tags.length === 0 ? "default" : "pointer",
            marginLeft: "16px",
            opacity: tags.length === 0 ? 0.4 : 1,
          }}
          onClick={() => {
            if (tags.length === 0) return;
            update();
            close && close();
          }}
        >
          Confirm
        </Typo>
        <Typo
          variant="button"
          style={{
            color: colors.grey[80],
            cursor: "pointer",
          }}
          onClick={() => {
            clearAll();
            close && close();
          }}
        >
          Clear all
        </Typo>
      </SetButtonContainer>
    </>
  );
};

export default AnalyticsTagSelection;
